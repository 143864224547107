/**
 * @file Various util functions to allow for generalized and consistent parsing of dates and date-like strings.
 *
 * @see {@link https://tc39.es/ecma262/#sec-date-objects|ECMAScript specification}
 * @see {@link https://tc39.es/proposal-temporal/docs/|Temporal API ECMAScript proposal}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date|MDN documentation}
 */

import {appLocale} from './locale';

export const appTimezone: Intl.DateTimeFormatOptions['timeZone'] = 'Europe/Amsterdam';

const defaultDateFormattingOptions: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  day: 'numeric',
  month: 'long',
};

const withYearDateFormattingOptions: Intl.DateTimeFormatOptions = {
  ...defaultDateFormattingOptions,
  year: 'numeric',
};

const longDateFormattingOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'long',
  year: 'numeric',
};

const numericDateFormattingOptions: Intl.DateTimeFormatOptions = {
  day: 'numeric',
  month: 'numeric',
  year: 'numeric',
};

const defaultTimeFormattingOptions: Intl.DateTimeFormatOptions = {
  hour: 'numeric',
  minute: 'numeric',
};

type TYear = `${number}${number}${number}${number}`;
type TMonth = `${number}${number}`;
type TDay = `${number}${number}`;

type THours = `${number}${number}`;
type TMinutes = `${number}${number}`;
type TSeconds = `${number}${number}`;
type TMilliseconds = `${number}${number}${number}`;

/**
 * Various time parts are optional; therefore we allow both with and without milliseconds in our type definitions.
 *
 * Laravel note: PHP/Carbon by default use 6 instead of 3 decimals of precision (milli- vs microseconds),
 * however in practice conversion both ways seems to work without issue.
 *
 * @see {@link https://tc39.es/ecma262/#sec-date-time-string-format|ECMAScript Date Time String Format specification}
 */
type TISOTimeHoursMinutes = `${THours}:${TMinutes}`;
type TISOTimeHoursMinutesSeconds = `${TISOTimeHoursMinutes}:${TSeconds}`;
type TISOTimeFull = `${TISOTimeHoursMinutesSeconds}.${TMilliseconds}`;

type DateString = TISODate | TISODateTime;

// Expose date-related string formats for global usage
declare global {
  type TISODate = `${TYear}-${TMonth}-${TDay}`;
  type TISOTime = TISOTimeHoursMinutes | TISOTimeHoursMinutesSeconds | TISOTimeFull;
  type TISODateTime = `${TISODate}T${TISOTime}Z`;

  // Extend the TypeScript native Date interface
  interface Date {
    toISOString(): TISODateTime;
  }
}

const TISODateRegexPattern = /^\d{4}-\d{2}-\d{2}/; // Starts with YYYY-MM-DD

export enum DateFormatType {
  Default,
  Birthday,
  Long,
  Numeric,
  WithYear,
  Year,
}

const getFormatOptions = (formatType: DateFormatType): Intl.DateTimeFormatOptions => {
  switch (formatType) {
    case DateFormatType.Birthday:
    case DateFormatType.Long:
      return longDateFormattingOptions;
    case DateFormatType.Numeric:
      return numericDateFormattingOptions;
    case DateFormatType.WithYear:
      return withYearDateFormattingOptions;
    case DateFormatType.Year:
      return {
        year: 'numeric',
      };
    default:
      return defaultDateFormattingOptions;
  }
};

/**
 * Convert date-string with or without timepart into Date object.
 * When the time zone offset is absent,
 * date-only forms are interpreted as a UTC time and date-time forms are interpreted as local time.
 * Therefore, date-only form return results are considered UTC-based,
 * while we require time zone specified on date-time forms.
 *
 * @see {@link https://tc39.es/ecma262/#sec-date-time-string-format|ECMAScript Date Time String Format specification}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format|MDN Date time string format documentation}
 */
const dateStringToDate = (dateString: DateString) => {
  if (!TISODateRegexPattern.test(dateString)) {
    throw new Error(`Date ${dateString} has invalid format`);
  }

  return new Date(dateString);
};

/**
 * Format a Date object according to specified format type and custom options.
 * As this is a Date object and we cannot assure its origin, by default we assume it to be a correct date,
 * and use application timezone and locale for formatting.
 */
export const formatDate = (
  date: Date,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => date.toLocaleDateString(
  appLocale,
  {
    timeZone: appTimezone,
    ...getFormatOptions(formatType),
    ...customOptions,
  },
);

/**
 * Format a date-only string of the form YYYY-MM-DD in specified format type.
 * As date-only forms (without time zone offset) are interpreted as a UTC time,
 * we ensure this is used as timezone when formatting.
 *
 * @see {@link dateStringToDate}
 */
export const formatDateString = (
  dateString: TISODate,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => formatDate(
  dateStringToDate(dateString),
  formatType,
  {
    timeZone: 'UTC', // partial string to date parsing will result in a zero-time UTC-based string
    ...customOptions,
  },
);

/**
 * Format a date-time string of the form YYYY-MM-DDTHH:mm:ss(.sss)Z in specified format type.
 * As date-time forms require time zone offset information,
 * we can safely convert them to use application timezone when formatting.
 *
 * @see {@link dateStringToDate}
 */
export const formatDateTimeString = (
  dateString: TISODateTime,
  formatType = DateFormatType.Default,
  customOptions: Intl.DateTimeFormatOptions = {},
) => formatDate(dateStringToDate(dateString), formatType, customOptions);

/**
 * Format only the timestring part of a date-time string of the form YYYY-MM-DDTHH:mm:ss(.sss)Z in specified format type.
 * This should preferably be replaced with some time-only object in the future when the Temporal API is implented.
 * As date-time forms require time zone offset information,
 * we can safely convert them to use application timezone when formatting.
 *
 * @see {@link https://tc39.es/proposal-temporal/docs/#Temporal-PlainTime|Temporal PlainTime object}
 */
export const formatTimeString = (dateString: TISODateTime) => dateStringToDate(dateString)
  .toLocaleTimeString(
    appLocale,
    {
      timeZone: appTimezone,
      ...defaultTimeFormattingOptions,
    },
  ) as TISOTimeHoursMinutes;

/**
 * Return the year of a date-string without time part.
 * As date-only forms (without time zone offset) are interpreted as a UTC time,
 * we can use getUTCFullYear to obtain the correct result.
 */
export const dateStringGetYear = (dateString: TISODate) => dateStringToDate(dateString)
  .getUTCFullYear();

/**
 * Return the year of a date-string without time part.
 * As date-time forms require time zone offset information,
 * we can safely convert them to use application timezone when formatting.
 */
export const dateTimeStringGetYear = (dateString: TISODateTime) =>
  Number(formatDate(dateStringToDate(dateString), DateFormatType.Year));

// Introduced for readability purposes
const August = 8;

/**
 * Return the SSL exam-year that the date is considered part of.
 * By default, we assume 1 August to be the rollover date, thus (pseudocode):
 * @example getExamYear(`1st of August 2024`) => 2025
 * @example getExamYear(`1st of January 2025`) => 2025
 * @example getExamYear(`1st of July 2025`) => 2025
 *
 * @param [splitMonth=August] - The first month considered to be part of the 'next' exam year.
 */
export const getExamYear = (date: Date, splitMonth = August) => {
  const [year, month] = [date.getFullYear(), date.getMonth()];

  return month >= splitMonth - 1 // getMonth is 0-indexed
    ? year + 1
    : year;
};

/**
 * Return the SSL exam-year for today.
 *
 * @see {@link getExamYear}
 */
export const getCurrentExamYear = (splitMonth = August) => getExamYear(new Date(), splitMonth);
